import styled from "@emotion/styled";
import { Theme } from "theme";

const SideNavPipeline = ({ className = "" }: SideNavPipelineProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    className={className}
  >
    <defs>
      <style>.cls-1{}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M13.51,4.18a1.31,1.31,0,1,0,1.31,1.31A1.31,1.31,0,0,0,13.51,4.18Z"
          fill="currentColor"
        />
        <path
          className="cls-1"
          d="M16,0H2A2,2,0,0,0,0,2V16a2,2,0,0,0,2,2H16a2,2,0,0,0,2-2V2A2,2,0,0,0,16,0ZM5,15.8a.2.2,0,0,1-.2.2H2.2a.2.2,0,0,1-.2-.2V12.2a.2.2,0,0,1,.2-.2H4.8a.2.2,0,0,1,.2.2Zm0-5a.2.2,0,0,1-.2.2H2.2a.2.2,0,0,1-.2-.2V2.2A.2.2,0,0,1,2.2,2H4.8a.2.2,0,0,1,.2.2Zm4,5a.2.2,0,0,1-.2.2H6.2a.2.2,0,0,1-.2-.2V2.2A.2.2,0,0,1,6.2,2H8.8a.2.2,0,0,1,.2.2Zm4,0a.2.2,0,0,1-.2.2H10.2a.2.2,0,0,1-.2-.2V10.2a.2.2,0,0,1,.2-.2h2.6a.2.2,0,0,1,.2.2Zm4-9.87-1,.44a.84.84,0,0,1-.06.14,1.17,1.17,0,0,0-.05.11l.44,1-.63.62-1-.43-.19.09,0,0L14,9h-.87l-.44-1-.15-.07-.09,0-1,.43-.63-.62.43-1c0-.07-.07-.15-.11-.24l-1-.43V5.06l1-.43.11-.25-.43-1,.63-.62,1,.43A1.28,1.28,0,0,1,12.64,3l.44-1H14l.44,1,.12,0,.12.06,1-.43.63.62-.44,1a.8.8,0,0,1,.11.25l1,.43Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

interface SideNavPipelineProps {
  className?: string;
  selected?: boolean;
}
const StyledPipeline = styled(SideNavPipeline)(
  ({ selected, theme }) => `
  .secondaryColor {
    fill: ${selected ? theme.colors.highlight : "white"};
  }
  @media (max-width: 768px) {
    .secondaryColor {
      fill: ${selected ? theme.colors.highlight : theme.colors.secondary};
    }

  }
`
);

export default StyledPipeline;
