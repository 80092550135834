import { ContactsModal, useContactsModal } from "components/Contacts/effects";
import { useSourcingSavedSearches } from "./hooks";
import { SavedSearchCard } from "./SavedSearchCard";
import { CardSkeletons } from "./Skeletons";
import { SavedSearchesContainer, ViewMore } from "./style";

type Props = {
  title: string;
  text: string;
  limit?: number;
};
export const SavedSeaches = ({ title, text, limit }: Props): JSX.Element => {
  const { data, isLoading } = useSourcingSavedSearches();
  const { show } = useContactsModal();
  const limitedData = limit
    ? Array.isArray(data) && data?.slice(0, limit)
    : data;
  const leftOver = data.length - limitedData.length;

  const openSavedSearchesModal = () => {
    show("savedSearches" as ContactsModal);
  };

  return data?.length > 0 ? (
    <SavedSearchesContainer p={{ xs: 1, md: 4 }}>
      <h3>{title}</h3>
      <div>{text}</div>
      {isLoading ? (
        <CardSkeletons />
      ) : (
        limitedData?.map((item) => (
          <SavedSearchCard key={item?.id} item={item} />
        ))
      )}
      {data.length > limit && (
        <ViewMore
          onClick={openSavedSearchesModal}
        >{`view ${leftOver} more search${leftOver > 1 ? "es" : ""}`}</ViewMore>
      )}
    </SavedSearchesContainer>
  ) : (
    <></>
  );
};
