import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useUser } from "lib/auth/hook";
import { getPowerBarOpenState } from "store/searches/selectors";
import { NavButton } from "./NavButton";
import PipelineIcon from "./SideNavPipeline";
import LeadsIcon from "./SideNavLeads";
import MarketMapIcon from "./SideNavMarketMappingIcon";
import TalentSourcingIcon from "./SideNavTalentSourcingIcon";
import SavedIcon from "./SideNavSaved";
import HomeIcon from "./SideNavHome";
import VacanciesIcon from "./SideNavVacancies";
import { PermissionKey, useFeature } from "lib/features";
import { isMobile } from "utils/mobile-check";
import { createSelector } from "reselect";
import HelpGuides from "./HelpGuides";
import { PERMISSIONS } from "constants/index";
import { RiSendPlaneFill } from "react-icons/ri";
import { SOURCING } from "../Sourcing/SplashScreen/index";
import { useTheme } from "../../theme";

const PlaceholderDiv = styled.div`
  width: 70px;
  height: 80px;
`;
const Placeholder = () => <PlaceholderDiv />;

const StyledNavLink = dynamic(() => import("./StyledNavLink"), {
  ssr: false,
  loading: Placeholder,
});

const FixedWrapper = styled.div`
  position: fixed;
  background-color: white;
  height: 100%;
  z-index: 1000;
  left: 0;

  @media (max-width: 768px) {
    height: 70px;
    width: 100vw;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 999;
    background: ${({ theme }) => theme.colors.secondary};
  }
`;

const customSelector = createSelector(
  getPowerBarOpenState,
  (isPowerBarOpen) => ({ isPowerBarOpen })
);

export const SideNav = (): JSX.Element => {
  const { hasMarketMapping, hasTalentSourcing, isLoggedIn } = useFeature();
  const { isPowerBarOpen } = useSelector(customSelector);
  const router = useRouter();
  const { permissions } = useUser();
  const homeRoute = isLoggedIn ? "/home" : "/"; // home route for a logged in user should be /home
  const hasPipelinePermissions = permissions.includes(
    PERMISSIONS.READ_PIPELINE_TASK as PermissionKey
  );
  const mobile = global && global.window && isMobile();
  const sourcingTooltip =
    SOURCING === "Sourcing" ? "Talent Sourcing" : SOURCING;

  const vacanciesTitle = useTheme().content.vacanciesName;

  if (mobile && isPowerBarOpen) return <div />;
  return (
    <FixedWrapper data-test="sideNavigation">
      <StyledNavLink to={homeRoute} hiddenInMobile>
        <NavButton testName="homeNavButton" tooltip="Go to homepage">
          <HomeIcon />
          <span>Home</span>
        </NavButton>
      </StyledNavLink>

      <StyledNavLink to="/news">
        <NavButton tooltip="View news" testName="newsNavButton">
          <LeadsIcon />
          <span>News</span>
        </NavButton>
      </StyledNavLink>

      {hasTalentSourcing && (
        <StyledNavLink to={`/${SOURCING.toLocaleLowerCase()}`}>
          <NavButton
            tooltip={`View ${sourcingTooltip}`}
            testName="talentSourcingNavButton"
          >
            <TalentSourcingIcon />
            <span>{SOURCING}</span>
          </NavButton>
        </StyledNavLink>
      )}

      {hasMarketMapping && (
        <StyledNavLink to="/market-mapping">
          <NavButton
            tooltip="View Market Mapping"
            testName="marketMappingNavButton"
          >
            <MarketMapIcon />
            <span>Market Mapping</span>
          </NavButton>
        </StyledNavLink>
      )}

      <StyledNavLink to="/vacancies">
        <NavButton tooltip="View vacancies" testName="vacanciesNavButton">
          <VacanciesIcon />
          <span>{vacanciesTitle}</span>
        </NavButton>
      </StyledNavLink>
      <StyledNavLink to="/projects" disabled={!hasPipelinePermissions}>
        <NavButton
          testName="pipelineNavButton"
          allowed={hasPipelinePermissions}
          tooltip={
            hasPipelinePermissions
              ? "View your Projects"
              : "Project is a premium feature"
          }
        >
          <PipelineIcon
            selected={
              router.pathname === "/projects" ||
              router.pathname === "/projects/[userId]"
            }
          />
          <span>Projects</span>
        </NavButton>
      </StyledNavLink>

      {isLoggedIn && (
        <StyledNavLink to="/saved-searches">
          <NavButton
            tooltip="View your saved searches"
            testName="savedSearchesNavButton"
          >
            <SavedIcon />
            <span>Saved</span>
          </NavButton>
        </StyledNavLink>
      )}

      {isLoggedIn && (
        <StyledNavLink to={["/outbox", "/templates", "/sequencing"]}>
          <NavButton tooltip="View your contacts" testName="contatcsNavButton">
            <RiSendPlaneFill />
            <span>Contact</span>
          </NavButton>
        </StyledNavLink>
      )}

      <HelpGuides />
    </FixedWrapper>
  );
};
