import styled from "@emotion/styled";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "theme";

const Wrapper = styled.div`
  align-self: center;
  display: block;
  height: 32px;

  @media (max-width: 768px) {
    width: 30px;
  }
`;

const LoginNavButton = (): JSX.Element => {
  const { productName } = useTheme().meta;
  const tooltipTitle = `Log in to your ${productName} account`;
  return (
    <Tooltip title={tooltipTitle} placement="bottom">
      <Wrapper>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.372"
          height="26.112"
          viewBox="0 0 18.372 26.112"
          fill="currentColor"
        >
          <defs>
            <style />
          </defs>
          <path
            id="Path_10287"
            d="M73.8 15.4h-1.728V1.864h-11.2c1.45.846 2.609 1.584 3.825 2.207a1.731 1.731 0 0 1 1.023 1.786c-.035 3.869-.015 7.74-.015 11.61v1.51h6.315V1.209h1.772c0 1.946.028 16.79-.014 18.693-.017.766-.592.948-1.259.944-1.815-.01-3.629 0-5.447 0H65.7c0 1.085.029 2.084-.008 3.081-.043 1.156-.607 1.483-1.6.92-2.327-1.317-4.654-2.635-6.939-4.024a1.708 1.708 0 0 1-.69-1.234c-.044-6.124-.023-12.248-.038-18.373C56.427.332 56.823 0 57.645 0q7.447.014 14.895 0c.665 0 1.236.172 1.246.951.03 2.362.014 11.998.014 14.449z"
            className="cls-1"
            data-name="Path 10287"
            transform="translate(-55.93 .5)"
          />
        </svg>
      </Wrapper>
    </Tooltip>
  );
};

export default LoginNavButton;
