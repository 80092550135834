import { useState } from "react";
import { Popover, DialogActions, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { FaExpandAlt } from "react-icons/fa";
import { Header } from "./styles";

export const HELP_GUIDE_URL =
  process.env.NEXT_PUBLIC_THEME === "talentticker"
    ? "https://talent-ticker-support.super.site/"
    : "https://anaxym-support.super.site/";

type ModalContent = {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLButtonElement;
};

const HelpGuidesModal = ({
  open,
  onClose,
  anchorEl,
}: ModalContent): JSX.Element => {
  const [expand, setExpand] = useState(false);

  const openInNewTab = () => {
    window.open(HELP_GUIDE_URL, "_blank");
  };

  return (
    <Popover
      id={open ? "help-guides-popover" : undefined}
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      data-testid="help-guides-popover"
    >
      <Header px={3} pr={1}>
        <h2 id="help-guide-dialog-title">Help & Guides</h2>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => setExpand((prev) => !prev)}
            data-testid="expand"
            size="large"
          >
            <FaExpandAlt style={{ width: 18, height: 18 }} />
          </IconButton>
          <IconButton
            color="primary"
            onClick={openInNewTab}
            data-testid="open-in-new"
            size="large"
          >
            <OpenInNewIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={onClose}
            data-testid="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Header>
      <Divider />
      <iframe
        src={HELP_GUIDE_URL}
        title="Help & Guides embed"
        allowFullScreen
        frameBorder="0"
        width={expand ? 1200 : 658}
        height={expand ? 839 : 681}
        style={{ transition: "0.25s ease-out" }}
      />
    </Popover>
  );
};

export default HelpGuidesModal;
