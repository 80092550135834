import { requestV2 } from "api/request";
import { useEffect } from "react";
import useSWR from "swr";
import { useSavedSearchContext } from "../hooks";
import { SavedSearchDTO } from "./types";

export type SourcingSavedSearchesDTO = SavedSearchDTO[];

const fetcher = async () =>
  await requestV2<SourcingSavedSearchesDTO>({
    method: "GET",
    url: `/v1-sourcing/searches`,
  });
export const useSourcingSavedSearches = () => {
  const { setSearches } = useSavedSearchContext();
  const { data } = useSWR("sourcing-saved-searches", fetcher, null);

  useEffect(() => {
    data && setSearches(data.data);
  }, [data, setSearches]);

  return { data: data?.data ?? [], isLoading: !data };
};
