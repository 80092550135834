import { ReactNode } from "react";
import styled from "@emotion/styled";
import Tooltip from "@mui/material/Tooltip";

const IconWrap = styled.div<Pick<Props, "allowed" | "size">>`
  cursor: ${(props) => (props.allowed ? "pointer" : "not-allowed")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  svg {
    object-fit: contain;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }

  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 10px;
    svg {
      width: 19.7px;
      height: 18.5px;
      margin: 0 1.6px 3.8px 1.7px;
    }
  }
`;

type Props = {
  children: ReactNode;
  tooltip: string;

  allowed?: boolean;
  size?: number;
  testName?: string;
};

export const NavButton = ({
  tooltip,
  children,
  allowed = true,
  testName,
  size = 24,
}: Props): JSX.Element => (
  <Tooltip title={tooltip} placement="bottom">
    <IconWrap
      data-test={testName}
      data-testid={testName}
      allowed={allowed}
      size={size}
    >
      {children}
    </IconWrap>
  </Tooltip>
);
