import styled from "@emotion/styled";

type WrapperProps = {
  selected: boolean;
  disabled: boolean;
  top?: boolean;
  hiddenInMobile?: boolean;
};

export const Wrapper = styled.a<WrapperProps>(
  ({ theme, disabled, selected, top, hiddenInMobile }) => `
    &:link,
    &:visited,
    &:hover,
    &:active {
      background-color: ${
        selected
          ? theme.colors.sideNav.background.selected
          : theme.colors.sideNav.background.default
      };
      color: ${
        disabled
          ? theme.colors.sideNav.text.disabled
          : selected
          ? theme.colors.sideNav.text.selected
          : theme.colors.sideNav.text.default
      };
      display: block;
      font-size: 12px;
      padding: 12px 8px;
      text-align: center;
      width: 70px;
      font-weight: 400;

      @media (max-width: 768px) {
        display: ${hiddenInMobile ? "none" : "flex"};
        align-items: center;
        justify-content: center;
        background-color: ${
          selected
            ? theme.colors.sideNav.background.mobile
            : theme.colors.sideNav.background.default
        };
        color: ${
          disabled
            ? theme.colors.sideNav.text.disabled
            : theme.colors.sideNav.text.selected
        };
        ${
          top
            ? `
              width: 50vw;
            `
            : `
              width: 70px;
              height: 70px;
              font-size: 12px;
            `
        }
      }
    }

    &:hover {
      text-decoration: none;
      color: ${
        disabled
          ? theme.colors.sideNav.text.disabled
          : selected
          ? theme.colors.sideNav.text.selectedHover
          : theme.colors.sideNav.text.default
      };

      @media (max-width: 768px) {
        color: ${
          disabled
            ? theme.colors.sideNav.text.disabled
            : selected
            ? theme.colors.sideNav.text.mobileSHover
            : theme.colors.sideNav.text.mobileDefaultHoover
        };
      }
    }
  `
);
