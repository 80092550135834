const SideNavSaved = ({ color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Group_5280"
    width="28.543"
    height="28.738"
    data-name="Group 5280"
    viewBox="0 0 28.543 28.738"
  >
    <path
      id="Path_10112"
      d="M-11.733-369.12h2c.218.043.434.093.653.126a10.624 10.624 0 0 1 8.226 14.4 11.089 11.089 0 0 1-1.946 3.157c.2.065.374.19.483.147a2.521 2.521 0 0 1 3 .719c1.2 1.263 2.435 2.5 3.654 3.75.713.731 1.432 1.456 2.137 2.194a2.511 2.511 0 0 1-.809 4.063c-.162.067-.329.122-.493.182h-.86a3.762 3.762 0 0 1-1.53-.978c-1.9-1.976-3.832-3.929-5.742-5.9a2.426 2.426 0 0 1-.56-2.666c.109-.306.039-.453-.217-.584a10.648 10.648 0 0 1-6.286 2.585 10.581 10.581 0 0 1-10.983-7.975c-.157-.641-.238-1.3-.354-1.951v-1.355a1.33 1.33 0 0 0 .063-.231 10.57 10.57 0 0 1 7.875-9.331c.555-.146 1.122-.232 1.689-.352zm1 2.769a7.825 7.825 0 0 0-7.849 7.782 7.854 7.854 0 0 0 7.838 7.918 7.846 7.846 0 0 0 7.866-7.849 7.808 7.808 0 0 0-7.852-7.851z"
      fill={color}
      data-name="Path 10112"
      transform="translate(21.36 369.12)"
    />
    <path
      id="Path_10113"
      d="M-1.324-355.8a6.655 6.655 0 0 1 6.675 6.644 6.668 6.668 0 0 1-6.66 6.667 6.654 6.654 0 0 1-6.651-6.67 6.644 6.644 0 0 1 6.636-6.641zm.005 9.865H1.14c.422 0 .652-.2.68-.567a1.919 1.919 0 0 0-2.452-2.059 2.873 2.873 0 0 1-1.368.006 2.457 2.457 0 0 0-1.082-.009 1.924 1.924 0 0 0-1.345 1.79c-.086.557.16.836.72.838.796.003 1.592.001 2.388 0zm.027-6.7a1.684 1.684 0 0 0-1.736 1.685 1.712 1.712 0 0 0 1.721 1.75 1.7 1.7 0 0 0 1.714-1.715 1.675 1.675 0 0 0-1.698-1.724z"
      fill={color}
      data-name="Path 10113"
      transform="translate(11.942 359.758)"
    />
  </svg>
);

export default SideNavSaved;
