import styled from "@emotion/styled";
import LoginNavButton from "./LoginNavButton";
import SignupNavButton from "./SignupNavButton";
import { StyledNavLink } from "./StyledNavLink";

const Wrapper = styled.div`
  bottom: 0;
  height: 100%;
  position: absolute;
  width: 70px;

  @media (max-width: 768px) {
    width: 100vw;
    height: 50px;
  }
`;

const FixedWrapper = styled.div`
  position: fixed;
  background-color: white;
  height: 100%;
  z-index: 1000;
  left: 0;

  @media (max-width: 768px) {
    height: 50px;
    display: flex;
    right: 0;
    top: 0;
  }
`;

const Align = styled.div`
  @media (max-width: 768px) {
    align-items: center;
    display: flex;
    margin: 0 auto;
  }
`;

export const LoggedOutSideNav = () => (
  <Wrapper>
    <FixedWrapper>
      <StyledNavLink to="/login" top>
        <Align>
          <LoginNavButton />
          <span>Log In</span>
        </Align>
      </StyledNavLink>
      <StyledNavLink to="/signup-online" top>
        <Align>
          <SignupNavButton />
          <span>Sign Up</span>
        </Align>
      </StyledNavLink>
    </FixedWrapper>
  </Wrapper>
);
