import { SavedSeaches } from "../SavedSearches/SavedSearchList";
import { useTheme } from "theme";
import { motion } from "framer-motion";
import { SplashScreenContainer, Paper, Wrapper } from "./style";

const sentence = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      staggerChildren: 0.03,
    },
  },
};

const letter = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export const SOURCING =
  process.env.NEXT_PUBLIC_THEME === "talentticker" ? "Sourcing" : "Prospecting";

export const SplashScreen = (): JSX.Element => {
  const theme = useTheme();

  const headerText = `Welcome to ${theme.meta.productName}`;
  const innerText = `Search for ${theme.content.sourcingIndividuals} by job title, location, industry and more.

Explore our talent database of over 600m ${theme.content.sourcingIndividuals} to find the
right fit.`;
  return (
    <SplashScreenContainer>
      <Wrapper>
        <Paper p={{ xs: 3, md: 4.5 }} width={{ lg: "70%" }}>
          <motion.h1 variants={sentence} initial="hidden" animate="visible">
            {headerText.split("").map((char, index) => (
              <motion.span key={`${char}-${index}`} variants={letter}>
                {char}
              </motion.span>
            ))}
            <span className="sourcing">
              {SOURCING.split("").map((char, index) => (
                <motion.span key={`${char}-${index}`} variants={letter}>
                  {char}
                </motion.span>
              ))}
            </span>
          </motion.h1>
          <p>{innerText}</p>
          <img
            src={`static/images/sourcing-${theme.key}.png`}
            alt={`${SOURCING}-img`}
          />
        </Paper>
        <SavedSeaches
          limit={3}
          title="Your recent saved searches"
          text="Jump back in and pick up where you left off."
        />
      </Wrapper>
    </SplashScreenContainer>
  );
};
