const SideNavLeads = ({ color = "currentColor" }: { color?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.932"
    height="21.07"
    viewBox="0 0 21.932 21.07"
  >
    <path
      fill={color}
      d="M-2525.127 232.154V219.5h6.911c-.038-.211-.068-.423-.1-.633v-.721a.758.758 0 0 0 .033-.123 5.617 5.617 0 0 1 4.171-4.964c.294-.078.595-.123.895-.187h1.059c.115.023.23.05.345.067a5.627 5.627 0 0 1 4.01 2.779 5.675 5.675 0 0 1 .347 4.881 5.91 5.91 0 0 1-1.031 1.679c.105.035.2.1.255.078a1.332 1.332 0 0 1 1.589.383c.635.672 1.29 1.33 1.935 1.995.378.389.759.775 1.132 1.167a1.34 1.34 0 0 1 .353 1.194 1.335 1.335 0 0 1-.781.967c-.086.036-.175.065-.261.1h-.456a1.993 1.993 0 0 1-.81-.52c-1.006-1.051-2.029-2.09-3.041-3.139a1.3 1.3 0 0 1-.3-1.418c.058-.163.021-.241-.114-.311a5.628 5.628 0 0 1-3.33 1.375 5.57 5.57 0 0 1-2.069-.247v8.134a1.809 1.809 0 0 1-.518 1.353 1.766 1.766 0 0 1-1.338.524l-8.891.031.005-1.79zm2.088-1.056a.467.467 0 0 0 .464.469h5.637a.467.467 0 0 0 .464-.469.467.467 0 0 0-.464-.469h-5.637a.467.467 0 0 0-.464.471zm0-3.05a.467.467 0 0 0 .464.469h5.637a.467.467 0 0 0 .464-.469.467.467 0 0 0-.464-.469h-5.637a.467.467 0 0 0-.464.47zm.188-3.049h3.712v-3.285h-3.712zm6-6.512a4.188 4.188 0 0 0 1.2 2.973 4.149 4.149 0 0 0 2.948 1.239 4.145 4.145 0 0 0 2.946-1.219 4.185 4.185 0 0 0 1.221-2.956 4.165 4.165 0 0 0-1.212-2.96 4.127 4.127 0 0 0-2.947-1.216h-.016a4.154 4.154 0 0 0-4.139 4.14zm1.663 2.537a3.556 3.556 0 0 1-1.028-2.509 3.526 3.526 0 0 1 3.51-3.534 3.507 3.507 0 0 1 2.493 1.032 3.55 3.55 0 0 1 1.037 2.5 3.538 3.538 0 0 1-3.522 3.548 3.509 3.509 0 0 1-2.489-1.035zm1.553-2.193a1.023 1.023 0 0 0-.711.953c-.046.3.084.445.381.446H-2511.402a.312.312 0 0 0 .36-.3 1.026 1.026 0 0 0-.356-.9 1.01 1.01 0 0 0-.941-.2 1.511 1.511 0 0 1-.724 0 1.294 1.294 0 0 0-.3-.035 1.293 1.293 0 0 0-.272.038zm1.582-.6a.908.908 0 0 0 .263-.648.9.9 0 0 0-.254-.652.885.885 0 0 0-.644-.266.887.887 0 0 0-.648.253.9.9 0 0 0-.27.644.915.915 0 0 0 .26.659.9.9 0 0 0 .65.272.9.9 0 0 0 .644-.264z"
      transform="translate(2525.132 -212.875)"
    />
  </svg>
);

export default SideNavLeads;
