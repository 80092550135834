import { ActionContainer, SavedCard, SearchContainer } from "./style";
import Delete from "@mui/icons-material/Delete";
import { CircularProgress, IconButton } from "@mui/material";
import Button from "components/atoms/Button";
import React, { useCallback, useState } from "react";
import { SavedSearchDTO } from "./types";
import { useDispatch } from "react-redux";
import { useMessageContext } from "components/Messaging/MessagingContext";
import { useSourcingContext } from "../hooks";
import { deleteSearch } from "./api";
import * as Sentry from "@sentry/browser";
import { useSWRConfig } from "swr";

type Props = {
  item: SavedSearchDTO;
};

export const SavedSearchCard = ({ item }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const { clearRecipients, recipients } = useMessageContext();
  const { mutate } = useSWRConfig();
  const {
    setSearchFilters,
    setCurrentFilters,
    fetch,
    clearAll,
    setPage,
    saveFilters,
  } = useSourcingContext();

  const handleRemove = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      try {
        setDeleting(true);
        e.preventDefault();
        await deleteSearch(item.id);
        await mutate("sourcing-saved-searches");
        setDeleting(false);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [item.id, mutate]
  );

  const handleSearch = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      clearAll();
      clearRecipients(recipients);
      setCurrentFilters(item.state);
      setSearchFilters(item.state);
      saveFilters(item.state);
      fetch();
      setPage(1);
      dispatch({
        type: "TALENT_SOURCING_SEARCH_REQUEST",
        payload: { filters: { ...item.state } },
      });
    },
    [
      // not a silly amount of dependencies at all!
      clearRecipients,
      clearAll,
      dispatch,
      fetch,
      item.state,
      recipients,
      setSearchFilters,
      setCurrentFilters,
      setPage,
      saveFilters,
    ]
  );

  return (
    <SavedCard elevation={0}>
      <h4>{item.name}</h4>
      <ActionContainer>
        <IconButton
          disableRipple
          disableFocusRipple
          onClick={handleRemove}
          size="large"
        >
          {deleting ? <CircularProgress size={20} /> : <Delete />}
        </IconButton>
        <SearchContainer>
          <Button
            className="search-now"
            data-testid="search-btn"
            clickHandler={handleSearch}
          >
            Search now
          </Button>
        </SearchContainer>
      </ActionContainer>
    </SavedCard>
  );
};
