import { useState } from "react";
import { NavButton } from "../NavButton";
import HelpIcon from "@mui/icons-material/Help";
import HelpGuidesModal from "./HelpGuidesModal";
import { HelpGuideWrapper, StyledButton } from "./styles";

const HelpGuides = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    // Hidden on mobile
    <HelpGuideWrapper display={{ xs: "none", md: "block" }}>
      <StyledButton
        disableRipple
        onClick={handleClick}
        data-testid="help-guides-btn"
      >
        <NavButton tooltip="View our help guide">
          <HelpIcon />
          <span>Help</span>
        </NavButton>
      </StyledButton>

      <HelpGuidesModal open={open} onClose={handleClose} anchorEl={anchorEl} />
    </HelpGuideWrapper>
  );
};

export default HelpGuides;
