import { SideNavIconType } from "../SideNavIconType";

type Props = SideNavIconType;
const SideNavTalentSourcingIcon = ({
  color = "currentColor",
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 26 26"
  >
    <path
      data-name="Icon open-target"
      d="M12.615 0A12.615 12.615 0 1 0 25.23 12.615 12.652 12.652 0 0 0 12.615 0zm0 3.154a9.461 9.461 0 1 1-9.461 9.461 9.449 9.449 0 0 1 9.461-9.461zm0 3.154a6.308 6.308 0 1 0 6.308 6.308 6.326 6.326 0 0 0-6.308-6.308zm0 3.154a3.154 3.154 0 1 1-3.154 3.154 3.123 3.123 0 0 1 3.154-3.155z"
      fill={color}
    />
  </svg>
);

export default SideNavTalentSourcingIcon;
