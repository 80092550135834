const SideNavLeads = ({ color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.414"
    height="23.909"
    viewBox="0 0 25.414 23.909"
  >
    <g
      id="Group_5172"
      data-name="Group 5172"
      transform="translate(-429.918 -410.78)"
    >
      <path
        id="Path_10124"
        d="M437.313 410.78v20.99a4.442 4.442 0 0 1-1.107 2.919h16.012a2.98 2.98 0 0 0 3.114-3.114v-20.8zm-7.395 4.67v16.32a2.919 2.919 0 1 0 5.838 0v-16.32zm11.213-1h6.227v5.45h-6.227zm.463 9.73h9.456a.778.778 0 1 1 0 1.557h-9.456a.778.778 0 1 1 0-1.557zm0 5.06h9.456a.778.778 0 1 1 0 1.557h-9.456a.778.778 0 1 1 0-1.557z"
        fill={color}
        data-name="Path 10124"
      />
    </g>
  </svg>

);

export default SideNavLeads;
