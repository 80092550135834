import styled from "@emotion/styled";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "theme";

const Wrapper = styled.div`
  align-self: center;
  display: block;
  height: 32px;
`;

const LoginNavButton = (): JSX.Element => {
  const { productName } = useTheme().meta;
  return (
    <Tooltip title={`Log in to your ${productName} account`} placement="bottom">
      <Wrapper data-test="signupNavButton">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32.99"
          height="32.99"
          viewBox="0 0 32.99 32.99"
          fill="currentColor"
        >
          <defs>
            <style />
          </defs>
          <g id="noun_Key_2577524" transform="rotate(45 14.446 15.568)">
            <path
              id="Path_10288"
              d="M8.773 22.55a7.769 7.769 0 0 0 7.321-5.17h13.425a2.574 2.574 0 0 0 2.581-2.748 2.686 2.686 0 0 0-2.721-2.4H28.21l-1.672-1.672a1.306 1.306 0 0 0-1.84 0l-1.672 1.672-1.672-1.672a1.306 1.306 0 0 0-1.84 0l-1.672 1.672H16.12A7.774 7.774 0 1 0 8.773 22.55zM6.181 12.183a2.592 2.592 0 1 1-2.592 2.592 2.6 2.6 0 0 1 2.592-2.592z"
              className="cls-1"
              data-name="Path 10288"
            />
          </g>
        </svg>
      </Wrapper>
    </Tooltip>
  );
};

export default LoginNavButton;
