import { Box, Button } from "@mui/material";
import styled from "@emotion/styled";

export const HelpGuideWrapper = styled(Box)`
  /* Help icon is placed on the bottom of nav bar */
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 80px;
  /* If the screen height is too small then its postion follow other icons  */
  @media (max-height: 769px) {
    position: static;
  }
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    width: 100%;
    color: ${({ theme }) => theme.colors.fontPrimary};
    background-color: #ffffff;

    &:hover {
      border-radius: 0;
      background-color: #ffffff;
    }
  }

  span {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
  }
`;
