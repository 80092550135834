import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const SplashScreenContainer = styled.div`
  display: flex;
  width: 100%;

  /** Account for ultra wide screens */
  @media only screen and (min-width: 1800px) {
    padding-right: calc(340px + 4 * 16px); /** Size of the form + margins */
    justify-content: center;
  }
`;

export const Wrapper = styled(Box)`
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 #e6e6e6;
  background-color: #fff;
  display: inline-flex;

  @media only screen and (max-width: 960px) {
    display: table-caption;
  }
`;

export const Paper = styled(Box)`
  text-align: center;
  margin: 0 auto;

  h1 {
    font-size: 40px;
    font-weight: bold;

    span.sourcing {
      margin-left: 8px;
    }
  }

  .sourcing {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
