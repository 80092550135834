import { Skeleton } from "@mui/material";

export const CardSkeletons = (): JSX.Element => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={382}
          height={120}
          style={{ borderRadius: 10, margin: "16px 0" }}
        />
      ))}
    </>
  );
};
