import { ReactNode } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Wrapper } from "./styles";

type LinkTypeProps = {
  href: string;
  disabled?: boolean;
  children: JSX.Element;
};

const LinkType = ({ href, disabled, children }: LinkTypeProps): JSX.Element =>
  disabled ? <div>{children}</div> : <Link href={href}>{children}</Link>;

type Props = {
  children: ReactNode;
  to: string | string[];
  top?: boolean;
  disabled?: boolean;
  hiddenInMobile?: boolean;
};

export const StyledNavLink = ({
  to,
  children,
  disabled,
  top,
  hiddenInMobile,
}: Props): JSX.Element => {
  const router = useRouter();
  const redirectTo = Array.isArray(to) ? to[0] : to;

  const isSelected = (pathName: string): boolean => {
    const isSelectedPredicate = (pathName: string, redirectTo: string) =>
      pathName === redirectTo ||
      (pathName === "/projects/[userId]" && redirectTo === "/projects") ||
      (pathName === "/home" && redirectTo === "/");
    if (Array.isArray(to)) {
      return to.some((item) => isSelectedPredicate(pathName, item));
    }
    return isSelectedPredicate(pathName, redirectTo);
  };

  return (
    <LinkType href={redirectTo} disabled={disabled}>
      <Wrapper
        data-testid="styled-anchor-tag"
        selected={isSelected(router.pathname)}
        href={disabled ? "" : redirectTo}
        disabled={disabled}
        top={top}
        hiddenInMobile={hiddenInMobile}
      >
        {children}
      </Wrapper>
    </LinkType>
  );
};

// Dynamically imported
export default StyledNavLink;
