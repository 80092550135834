const SideNavHome = ({ color = "currentColor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.229"
    height="23.135"
    viewBox="0 0 27.229 23.135"
  >
    <g id="Group_5166" data-name="Group 5166" transform="translate(.201)">
      <path
        id="Path_10200"
        d="M60.081 63.206v7.909A1.562 1.562 0 0 1 58.5 72.7h-4.647v-7.912a.554.554 0 0 0-.593-.494h-5.438a.554.554 0 0 0-.593.494V72.7h-4.548A1.587 1.587 0 0 1 41 71.115v-7.909L50.59 55z"
        fill={color}
        fillRule="evenodd"
        data-name="Path 10200"
        transform="translate(-37.128 -49.562)"
      />
      <path
        id="Path_10201"
        d="M2.092 13.347a1.4 1.4 0 0 1-1.977-.1 1.41 1.41 0 0 1 .2-1.977L13.462 0l6.723 5.833V3.856h2.768v4.35l3.559 3.065a1.369 1.369 0 1 1-1.78 2.076l-11.27-9.689z"
        fill={color}
        data-name="Path 10201"
      />
    </g>
  </svg>
);

export default SideNavHome;
